import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Container,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap"

import _, { capitalize, lowerCase, set } from "lodash"
import { useDropzone } from "react-dropzone"
import { ClipLoader } from "react-spinners"
import classnames from "classnames"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast from "react-hot-toast"
import { SHA256 } from "crypto-js"
import { DateTime } from "luxon"
import { reduce } from "radash"
import { v4 as uuidv4 } from "uuid"
import Papa from "papaparse"
import {
  addDiplomaBatch,
  callHemisDiplomaData,
  getPublicKey,
  getSignature,
  readMyOrg,
} from "helpers/api_helper"
import {
  DateCell,
  TextCell,
  TextToUpperCase,
} from "pages/CreditLog/creditLogCol"
import { IdCell } from "pages/AllDiplomaList/diplomaListCol"
import TableContainerWithModal from "components/Common/TableContainerWithModal"

// function capitalizeWordsMN(str) {
//   // Ensure str is defined and is a string
//   if (typeof str !== "string") {
//     console.error("capitalizeWordsMN expects a string as an argument")
//     return ""
//   }

//   // Proceed with capitalization
//   return str.replace(/\b\w/g, char => char.toUpperCase())
// }
function roundToTwoDecimal(num) {
  return Math.round(num * 100) / 100
}
const removeLastZero = string => {
  if (string.endsWith("0")) {
    return string.slice(0, -1)
  }
  return string
}

const capitalizeIfHyphenated = string => {
  if (!string) return ""
  if (string.includes("-")) {
    return string
      .split("-")
      .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join("-")
  }
  if (string.includes(" ")) {
    return string
      .split(" ")
      .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join(" ")
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

const AddDiplomaBatch = () => {
  const [isMatched, setIsMatched] = useState(false)
  const [csvData, setCsvData] = useState(null)
  const [validRows, setValidRows] = useState([])
  const [invalidRows, setInvalidRows] = useState([]) // row, msg
  const [isMatchLoading, setIsMatchLoading] = useState(false)
  const [isFinishedMatching, setIsFinishedMatching] = useState(false)
  const [callHemisDataError, setCallHemisDataError] = useState(false)
  const [hemisDataMap, setHemisDataMap] = useState(new Map())
  const [alert, setAlert] = useState("")

  const submitRef = useRef(null)
  const allDiplomaColumns = useMemo(
    () => [
      {
        Header: "Дипломын дугаар",
        accessor: "DEGREE_NUMBER",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <IdCell {...cellProps} />
        },
      },
      {
        Header: "Нэр",
        accessor: "FIRST_NAME",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Регистрийн дугаар",
        accessor: "PRIMARY_IDENTIFIER_NUMBER",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <TextToUpperCase {...cellProps} />
        },
      },
    ],
    []
  )
  const mismatchedDiplomaColumns = useMemo(
    () => [
      {
        Header: "Дипломын дугаар",
        accessor: "row.DEGREE_NUMBER",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <IdCell {...cellProps} />
        },
      },
      {
        Header: "Нэр",
        accessor: "row.FIRST_NAME",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Регистрийн дугаар",
        accessor: "row.PRIMARY_IDENTIFIER_NUMBER",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <TextToUpperCase {...cellProps} />
        },
      },
      {
        Header: "Таараагүй",
        accessor: "msg",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
    ],
    []
  )
  const matchedDiplomaColumns = useMemo(
    () => [
      {
        Header: "Дипломын дугаар",
        accessor: "row.DEGREE_NUMBER",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <IdCell {...cellProps} />
        },
      },
      {
        Header: "Нэр",
        accessor: "row.FIRST_NAME",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <TextCell {...cellProps} />
        },
      },
      {
        Header: "Регистрийн дугаар",
        accessor: "row.PRIMARY_IDENTIFIER_NUMBER",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <TextToUpperCase {...cellProps} />
        },
      },
    ],
    []
  )

  const handleSubmit = async () => {
    const getPublicKeyResponse = await getPublicKey()
    const pubKey = getPublicKeyResponse.pubKeyPem
    const myOrg = await readMyOrg()
    const orgId = myOrg.id
    const formattedPromises = csvData.map(async (c, index) => {
      const signersValues = c.SIGNERS.split(" ")
      const signers = []
      if (signersValues.length > 1) {
        const signerName = signersValues.pop();
        const signerTitle = signersValues.join(' ')
        signers.push({
          title: signerTitle,
          name: signerName,
        })
      }
      const hemisData = hemisDataMap.get(c.DEGREE_NUMBER)
      const totalGpa = hemisData
        ? parseFloat(hemisData.TOTAL_GPA)
        : parseFloat(c.TOTAL_GPA)

      const hemisDiploma = {
        DEGREE_NUMBER: c.DEGREE_NUMBER,
        PRIMARY_IDENTIFIER_NUMBER: c.PRIMARY_IDENTIFIER_NUMBER,
        INSTITUTION_ID: parseInt(hemisData.INSTITUTION_ID),
        INSTITUTION_NAME: hemisData.INSTITUTION_NAME,
        EDUCATION_LEVEL_NAME: hemisData.EDUCATION_LEVEL_NAME,
        EDUCATION_FIELD_CODE: hemisData.EDUCATION_FIELD_CODE,
        EDUCATION_FIELD_NAME: hemisData.EDUCATION_FIELD_NAME,
        TOTAL_GPA: totalGpa || 0,
        LAST_NAME: hemisData.LAST_NAME || "",
        FIRST_NAME: c.FIRST_NAME,
        CONFER_YEAR_NAME: c.CONFER_YEAR_NAME,
      }
      const hemisDiplomaString = JSON.stringify(hemisDiploma)
      const hemisDiplomaHashed = SHA256(hemisDiplomaString).toString()
      const [firstName, lastName] = hemisData.FIRST_NAME.includes(" ") ? [hemisData.FIRST_NAME.split(" ")[0], hemisData.FIRST_NAME.split(" ")[1]] : [hemisData.FIRST_NAME, hemisData.LAST_NAME]
      const claims = {
        diplomaNumber: c.DEGREE_NUMBER,
        language: c.LANGUAGE,
        firstName: firstName,
        lastName: lastName,
        email: c.EMAIL == null ? "" : c.EMAIL,
        institutionName: hemisData.INSTITUTION_NAME,
        educationLevelName: hemisData.EDUCATION_LEVEL_NAME,
        educationFieldName: hemisData.EDUCATION_FIELD_NAME,
        issuedOn: DateTime.now().toString(),
        extras: {
          hemisDiplomaHash: hemisDiplomaHashed,
          hemisDiploma,
          additionalText: c.ADDITIONAL_TEXT ? [c.ADDITIONAL_TEXT] : [],
          signer: signers,
        },
      }
      const claimsString = JSON.stringify(claims)
      const getSignatureResponse = await getSignature(claimsString)
      const signatureToSend = getSignatureResponse.data.signature

      // if (c.EDUCATION_FIELD_CODE < 6) {
      //   const leadingZeros = "0".repeat(6 - c.EDUCATION_FIELD_CODE.length)
      //   c.EDUCATION_FIELD_CODE = leadingZeros + c.EDUCATION_FIELD_CODE
      // }
      let orgSignProps, signOrgID

      if (c.LANGUAGE === "MN") {
        orgSignProps = {
          [orgId]: [{ key: "claims" }],
          "ORG-HEMIS": [{ key: "claims.extras.hemisDiploma" }],
        }
        signOrgID = [`${orgId}`, "ORG-HEMIS"]
      } else if (c.LANGUAGE === "EN") {
        orgSignProps = {
          [orgId]: [{ key: "claims" }],
        }
        signOrgID = [`${orgId}`]
      }
      return {
        createDiplomaArg: {
          id: uuidv4(),
          claims,
          orgSignProps: orgSignProps,

          pubKeyType: "ecdsa:P-384",
          pubKeyPem: pubKey,

          signature: signatureToSend,
          signOrgID: signOrgID,
        },
      }
    })

    const formattedResults = await Promise.all(formattedPromises)
    const createDiplomaBatchArg = JSON.stringify(formattedResults)
    const r = await addDiplomaBatch(createDiplomaBatchArg)

    if (r) {
      const err = r
      if (err?.message != "error") {
        setAlert("Амжилттай хадгаллаа!")
      } else {
        setAlert("Алдаа гарлаа!")
      }
    }
  }
  const handleFileUpload = acceptedFiles => {
    setCallHemisDataError(false)
    handleAcceptedFiles(acceptedFiles)
    setAlert("")
    setselectedFiles(acceptedFiles)
    setIsMatched(false)

    if (acceptedFiles[0]) {
      Papa.parse(acceptedFiles[0], {
        complete: result => {
          const filteredData = result.data.filter((row, idx) => {
            let inValidRowIdx,
              invalidRow = null
            Object.values(row).some(value => {
              const isValid =
                // value !== null && value !== undefined && value.trim() !== ""
                typeof value === "string"
                  ? value.trim() !== ""
                  : value !== null && value !== undefined
              if (!isValid) {
                inValidRowIdx = idx
                invalidRow = row
              }
              return isValid
            })
            if (inValidRowIdx && invalidRow) {
              return false
            }
            return true
          })
          const formattedData = filteredData.map(r => {
            const FIRST_NAME = capitalizeIfHyphenated(r?.FIRST_NAME)
            const LAST_NAME = capitalizeIfHyphenated(r?.LAST_NAME)
            return {
              ...r,
              FIRST_NAME: FIRST_NAME,
              LAST_NAME: LAST_NAME,
              PRIMARY_IDENTIFIER_NUMBER:
                r.PRIMARY_IDENTIFIER_NUMBER.toLowerCase(),
              EDUCATION_FIELD_NAME: capitalize(r.EDUCATION_FIELD_NAME),
              TOTAL_GPA: roundToTwoDecimal(r.TOTAL_GPA),
            }
          })

          setCsvData(formattedData)
        },
        header: true,
      })
    }
  }

  useEffect(() => {
    if (csvData) {
      const matchRowsWithHemis = async () => {
        const invalidRows = []
        const validRows = []
        const keysToMatch = [
          "DEGREE_NUMBER",
          "PRIMARY_IDENTIFIER_NUMBER",
          "FIRST_NAME",
        ]
        await reduce(
          csvData,
          async (ACC, row) => {
            if (row.LANGUAGE === "MN") {
              // if (
              //   row.EDUCATION_FIELD_CODE &&
              //   row.EDUCATION_FIELD_CODE.length < 6
              // ) {
              //   const leadingZeros = "0".repeat(
              //     6 - row.EDUCATION_FIELD_CODE.length
              //   )
              //   row.EDUCATION_FIELD_CODE =
              //     leadingZeros + row.EDUCATION_FIELD_CODE
              // }
              // console.log(row.EDUCATION_FIELD_CODE)
              row.EDUCATION_FIELD_CODE = removeLastZero(
                row.EDUCATION_FIELD_CODE
              )
              const id = row.DEGREE_NUMBER

              try {
                const hemisResponse = await callHemisDiplomaData({
                  id,
                })
                const hemisData = hemisResponse.RESULT[0]
                hemisData.FIRST_NAME = hemisData.FIRST_NAME.replace("\t", "")
                setHemisDataMap(prevMap =>
                  new Map(prevMap).set(row.DEGREE_NUMBER, hemisData)
                )
                console.log(hemisResponse)
                if (hemisResponse.SUCCESS_CODE !== 200) {
                  setCallHemisDataError(true)
                  setAlert(hemisResponse.RESPONSE_MESSAGE)
                } else if (hemisResponse.RESPONSE_MESSAGE) {
                  setAlert("HEMIS-тэй мэдээллийг тулгахад алдаа гарлаа!")
                }

                const misMatchKeys = keysToMatch.filter(key => {
                  if (key === "INSTITUTION_ID") {
                    return parseInt(row[key]) !== hemisData[key]
                  }
                  return hemisData[key] !== row[key]
                })

                const matchKeys = keysToMatch.filter(key => {
                  if (key === "INSTITUTION_ID") {
                    return parseInt(row[key]) === hemisData[key]
                  }
                  return hemisData[key] === row[key]
                })
                console.log("mistmatchkeys", misMatchKeys)
                console.log("matchkeys", matchKeys)

                if (misMatchKeys.length) {
                  invalidRows.push({
                    row,
                    msg: `${misMatchKeys.join(", ")}`,
                  })
                  setIsMatched(false)
                }
                if (matchKeys.length === keysToMatch.length) {
                  validRows.push({ row })
                }
                console.log("VALID ROWS LOOP", validRows)
                console.log("INVALID ROWS LOOP", invalidRows)
              } catch (error) {
                console.error(`Error fetching data for ID ${id}: ${error}`)
              }
            } else if (row.LANGUAGE === "ENG") {
              validRows.push({ row })
            }
            return +1
          },
          0
        )

        setIsMatched(!invalidRows.length)
        setInvalidRows(invalidRows)
        setValidRows(validRows)
      }
      const abortController = new AbortController()

      try {
        setIsMatchLoading(true)
        matchRowsWithHemis({
          signal: abortController.signal,
        }).finally(() => setIsMatchLoading(false), setIsFinishedMatching(true))
      } catch (e) {}
      return () => {
        // cleanup
        abortController.abort()
      }
    }
  }, [csvData])

  document.title = "Диплом нэмэх | Diplom.mn"
  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [tableData, setTableData] = useState(null)
  const [uploadType, setUploadType] = useState("all")
  const [showall, setIsShowAll] = useState(true)
  const [column, setColumn] = useState(allDiplomaColumns)
  const [modal1, setModal1] = useState(false)
  const toggleViewModal = () => setModal1(!modal1)

  useEffect(() => {
    if (csvData) setTableData(csvData)
  }, [csvData])
  const onChangeUploadType = data => {
    setUploadType(data)
    if (data == "all") {
      setColumn(allDiplomaColumns)
      setTableData(csvData)
      setIsShowAll(true)
    } else if (data == "matched") {
      setColumn(matchedDiplomaColumns)
      setTableData(validRows)
      setIsShowAll(false)
    } else if (data == "mismatch") {
      setColumn(mismatchedDiplomaColumns)
      setTableData(invalidRows)
      setIsShowAll(false)
    }
  }
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/csv": [
        ".csv",
        "application/csv",
        "text/x-csv",
        "application/x-csv",
        "text/comma-separated-values",
        "text/x-comma-separated-values",
      ],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls", "application/vnd.ms-excel"],
    },
    onDrop: acceptedFiles => handleFileUpload(acceptedFiles),
  })
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="БАЙРШУУЛАХ"
            breadcrumbItem="Багцлан диплом нэмэх"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <h6 className="card-title">Байршуулах</h6>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      handleSubmit()
                      return false
                    }}
                  >
                    <div className="dropzone" {...getRootProps()}>
                      <input type="file" accept=".csv" {...getInputProps()} />
                      <div className="dz-message needsclick mt-2">
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h4>Энд дарж файлаа байршуулна уу.</h4>
                      </div>
                    </div>

                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles
                        ? selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                      />
                                    </Col>
                                    <Col className="col-auto">
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                    {alert == "Амжилттай хадгаллаа!" ? (
                                      <Col className="col-auto ms-auto">
                                        <Alert
                                          className="text-center m-auto"
                                          color="success"
                                        >
                                          Амжилттай хадгаллаа!
                                        </Alert>
                                      </Col>
                                    ) : alert == "Алдаа гарлаа!" ? (
                                      <Col className="col-auto ms-auto">
                                        <Alert
                                          className="text-center m-auto"
                                          color="danger"
                                        >
                                          Алдаа гарлаа!
                                        </Alert>
                                      </Col>
                                    ) : !callHemisDataError &&
                                      isMatched &&
                                      csvData.length ? (
                                      <Col className="col-auto ms-auto">
                                        <button
                                          onClick={e => {
                                            if (submitRef.current) {
                                              submitRef.current.click(e)
                                            }
                                          }}
                                          type="submit"
                                          className="btn btn-primary my-2"
                                        >
                                          Хураалгах
                                        </button>
                                      </Col>
                                    ) : callHemisDataError ||
                                      (!isMatched && !isMatchLoading) ? (
                                      <Col className="col-auto ms-auto">
                                        <Alert
                                          className="text-center align-items-center m-auto"
                                          color="danger"
                                        >
                                          Файл дах мэдээлэл алдаатай байна !
                                          <br />
                                          {alert}
                                        </Alert>
                                      </Col>
                                    ) : (
                                      <></>
                                    )}
                                  </Row>
                                </div>
                              </Card>
                            )
                          })
                        : null}
                    </div>
                    {isMatchLoading ? (
                      <>
                        <Row className="align-items d-sm-flex flex-wrap mt-5">
                          <Col className="col-auto">
                            <ClipLoader color="black" className="" />
                          </Col>
                          <Col className="col-auto mt-1">
                            <span className="">
                              Файлдах өгөгдлийг шалгаж байна
                            </span>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {!callHemisDataError &&
          isFinishedMatching &&
          csvData &&
          csvData.length ? (
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <Card className="mini-stats-wid bg-primary bg-opacity-10">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Мэдээлэл таарсан дипломууд
                            </p>
                            <h4 className="mb-0">
                              {!isMatchLoading
                                ? validRows.length
                                : "Уншиж байна..."}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={"bx bx-check-double font-size-24"}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mini-stats-wid bg-primary bg-opacity-10">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Мэдээлэл таараагүй дипломууд
                            </p>
                            <h4 className="mb-0">
                              {!isMatchLoading
                                ? invalidRows.length
                                : "Уншиж байна..."}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mini-stats-wid bg-primary bg-opacity-10">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Нийт оруулсан диплом
                            </p>
                            <h4 className="mb-0">
                              {!isMatchLoading
                                ? csvData.length
                                : "Уншиж байна..."}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={"bx bx-archive-in font-size-24"}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <div className="d-sm-flex flex-wrap">
                  <h4 className="card-title mb-4">
                    Оруулсан дипломын жагсаалт
                  </h4>
                  <div className="ms-auto">
                    <ul className="nav nav-pills">
                      <li className="nav-item">
                        <Link
                          to="#"
                          className={classnames(
                            { active: uploadType === "all" },
                            "nav-link"
                          )}
                          onClick={() => {
                            onChangeUploadType("all")
                          }}
                          id="one_month"
                        >
                          Бүгд
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="#"
                          className={classnames(
                            { active: uploadType === "matched" },
                            "nav-link"
                          )}
                          onClick={() => {
                            onChangeUploadType("matched")
                          }}
                          id="one_month"
                        >
                          Мэдээлэл таарсан
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="#"
                          className={classnames(
                            { active: uploadType === "mismatch" },
                            "nav-link"
                          )}
                          onClick={() => {
                            onChangeUploadType("mismatch")
                          }}
                          id="one_month"
                        >
                          Мэдээлэл зөрсөн
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <TableContainerWithModal
                  columns={column}
                  data={tableData ? tableData : []}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  isPagination={true}
                  isShowingPageLength={false}
                  iscustomPageSizeOptions={true}
                  customPageSize={10}
                  pagination="pagination pagination-rounded justify-content-end mb-2"
                  UploadModalState={modal1}
                  UploadToggleViewModal={toggleViewModal}
                  isUpload={true}
                  isShowAll={showall}
                />
              </CardBody>
            </Card>
          ) : (
            <></>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddDiplomaBatch
